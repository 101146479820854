import { useClickOutside } from '@/utils/hooks/useClickOutside';
import cn from 'classnames';
import { Children, ReactElement, ReactNode, cloneElement } from 'react';
import { Portal } from './Portal';

type ContextMenuProps = {
    x: number;
    y: number;
    visible: boolean;
    onClose: () => void;
    children: ReactElement | ReactElement[] | any;
};

const ContextMenu = ({ x, y, visible, onClose, children }: ContextMenuProps) => {
    const ref = useClickOutside(() => onClose());
    const documentHeight = document.body.scrollHeight;
    const documentWidth = document.body.scrollWidth;
    const showBottom = documentHeight - y > 120;
    const showLeft = documentWidth - x > 190;

    return (
        <Portal>
            <div ref={ref}>
                {visible && (
                    <div
                        style={{ left: showLeft ? x : x - 190, top: showBottom ? y : y - 120 }}
                        className={cn(
                            'fixed z-50 w-48 py-1 bg-brand-background-dark rounded-md',
                            'border border-neutral-700 divide-y divide-white/10'
                        )}
                        onContextMenu={onClose}>
                        {children && Children.map(children, (child) => child && cloneElement(child, { onClose }))}
                    </div>
                )}
            </div>
        </Portal>
    );
};

export default ContextMenu;

type MenuItem = {
    onClick?: () => void;
    onClose?: () => void;
    disabled?: boolean;
    className?: string;
    children: ReactNode;
};

export const ContextMenuItem = ({ onClick, onClose, disabled = false, className, children }: MenuItem) => {
    const handleClick = () => {
        if (onClick) onClick();
        if (onClose) onClose();
    };
    return (
        <div
            className={cn(
                'flex gap-3 items-center text-sm rounded-sm select-none py-2 pl-3 pr-4',
                {
                    ['text-neutral-400 cursor-not-allowed']: disabled,
                    ['text-neutral-200 hover:bg-brand-red cursor-pointer']: !disabled
                },
                className
            )}
            onClick={handleClick}>
            {children}
        </div>
    );
};
