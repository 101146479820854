import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

// to be deprecated

interface Props extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
}

const RHFNumberInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { name, label, min = 0, step = 1, disabled, className } = props;
    const { getFieldState, getValues, formState, setValue } = useFormContext();
    const fieldState = getFieldState(name, formState);

    return (
        <div className="w-full gap-1 flex flex-col">
            {label ? <label className="block text-sm font-medium text-neutral-200">{label}</label> : null}
            <div className="relative flex">
                <input
                    {...props}
                    ref={ref}
                    autoComplete="off"
                    className={cn(
                        'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-red focus:border-brand-red',
                        { 'text-neutral-400 cursor-not-allowed': disabled },
                        { 'text-neutral-200': !className },
                        className
                    )}
                />
                <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-red-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = +(getValues(name) || 0);
                                const nextValue = BigNumber(prevValue).plus(step).toNumber();
                                setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25B2;
                    </button>
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-red-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = +(getValues(name) || 0);
                                const nextValue = BigNumber(prevValue).minus(step).toNumber();
                                if (nextValue <= +min) setValue(name, min, { shouldValidate: true });
                                else setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25BC;
                    </button>
                </div>
            </div>
            {fieldState.error ? <span className="text-brand-red italic">{fieldState.error?.message}</span> : null}
        </div>
    );
});

RHFNumberInput.displayName = 'RHFNumberInput';

export default RHFNumberInput;
