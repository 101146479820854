import { UseCurrencyReturn } from '@/utils/hooks/useCurrency';
import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';

interface CurrencySwitchProps {
    value: string;
    baseCurrency: UseCurrencyReturn;
    quoteCurrency: UseCurrencyReturn;
    disabled?: boolean;
    onChange: any;
    // onChange: (side: Side) => void;
}

export default function CurrencySwitch(props: CurrencySwitchProps) {
    const { baseCurrency, quoteCurrency, value, disabled, onChange } = props;

    return (
        <RadioGroup value={value} onChange={onChange} disabled={disabled}>
            <div className="flex flex-row rounded-sm overflow-hidden text-xs uppercase">
                <RadioGroup.Option
                    value={baseCurrency.celer}
                    className={cn('text-center focus:outline-none', { 'w-1/2': !disabled })}>
                    {({ checked }) => {
                        return (
                            <div
                                className={cn('px-3 py-1 cursor-pointer transition-colors border-2 rounded-l-sm', {
                                    'border-brand-red-dark text-neutral-200': checked,
                                    'border-neutral-600 hover:border-neutral-500 text-neutral-400 hover:text-neutral-300':
                                        !checked,
                                    'border-r-0': !disabled
                                })}>
                                {baseCurrency.show}
                            </div>
                        );
                    }}
                </RadioGroup.Option>
                <div className={cn('w-0.5 bg-brand-red', { hidden: disabled })} />
                <RadioGroup.Option value={quoteCurrency.celer} className="w-1/2 text-center focus:outline-none">
                    {({ checked }) => {
                        return (
                            <div
                                className={cn(
                                    'px-3 py-1 transition-colors border-2 border-l-0 rounded-r-sm cursor-pointer',
                                    {
                                        'border-brand-red-dark text-neutral-200': checked,
                                        'border-neutral-600 hover:border-neutral-500 text-neutral-400 hover:text-neutral-300':
                                            !checked && !disabled,
                                        hidden: disabled
                                    }
                                )}>
                                {quoteCurrency.show}
                            </div>
                        );
                    }}
                </RadioGroup.Option>
            </div>
        </RadioGroup>
    );
}
