import { Listbox, Transition } from '@headlessui/react';

import buildJson from '@/config/build.json';
import { getEnvironment } from '@/helpers/environmentHelper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    Account,
    selectCredentials,
    selectCurrentAccount,
    selectCurrentAccountName,
    setCurrentAccount,
    setCurrentAccountName
} from '@/state/reducers/authSlice';
import { navigateAccordingToAccountType } from '@/utils/navigation';
import { showLayoutSwitch } from '@/utils/route';
import cn from 'classnames';
import { ButtonHTMLAttributes, Fragment, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
    MdCandlestickChart,
    MdExpandMore,
    MdHistory,
    MdOfflineBolt,
    MdOutlineAccountBalance,
    MdOutlineAccountBalanceWallet,
    MdOutlineAccountBox,
    MdOutlineLogout,
    MdOutlineSettings
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Divider from '../common/Divider';
import { onLogout, onUserChange } from './Navbar';

const env = getEnvironment();
const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;

const MobileDrawer = (props: any) => {
    const { depositDisclosure, withdrawalDisclosure, settingsDisclosure, drawerDisclosure } = props;
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const currentAccountName = useAppSelector(selectCurrentAccountName);

    const navigate = useNavigate();

    const currentAccountType = useMemo(() => {
        const properties = credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [];
        return properties.find((property) => property.key === 'TYPE')?.value || 'NOP';
    }, [credentials, currentAccount]);

    return (
        <>
            <div
                className={cn(
                    'block lg:hidden h-full fixed z-30 right-0 top-0 w-3/4 sm:w-2/4 bg-brand-background-dark shadow-lg transform transition-transform duration-300 ease-in-out',
                    { ['translate-x-0']: drawerDisclosure[0], ['translate-x-full']: !drawerDisclosure[0] }
                )}>
                {/* <div className="absolute text-neutral-400 right-4 top-4">
                    <MdClose className="w-4 h-4" />
                </div> */}
                {/* Drawer content */}
                <div className="flex flex-col h-full justify-between">
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <div className="flex flex-col gap-2 py-4 text-neutral-200">
                                <div className="text-neutral-400 text-xs px-4">Account</div>
                                <Listbox
                                    value={{
                                        code: currentAccount,
                                        name: currentAccountName
                                    }}
                                    onChange={(account: any) => {
                                        dispatch(setCurrentAccount(account.code));
                                        dispatch(setCurrentAccountName(account.name));
                                        onUserChange(credentials, dispatch);
                                        const curr = credentials?.accounts.find(
                                            (acc) => acc.code === account.code
                                        ) as Account;
                                        navigateAccordingToAccountType(curr, navigate);
                                    }}>
                                    <div className="relative min-w-[150px] w-full">
                                        <Listbox.Button className="w-full">
                                            <div className="flex justify-between items-center px-4 py-2">
                                                <div>
                                                    <MdOutlineAccountBox className="w-5 h-5" />
                                                </div>
                                                <span className="text-sm truncate pl-4">{currentAccountName}</span>
                                                <div className="ml-auto">
                                                    <MdExpandMore className="w-5 h-5" />
                                                </div>
                                            </div>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            {/* temporary shadow to the dropdown to differentiate */}
                                            <Listbox.Options className="absolute z-[60] max-h-60 w-full overflow-auto rounded-md bg-brand-background-dark border border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900">
                                                {credentials?.accounts.map((account, accountIdx) => (
                                                    <Listbox.Option
                                                        key={accountIdx}
                                                        className={({ active }) =>
                                                            cn(
                                                                `relative cursor-pointer select-none flex gap-4 py-2 px-4`,
                                                                {
                                                                    [`bg-brand-red`]: active
                                                                }
                                                            )
                                                        }
                                                        value={account}>
                                                        <MdOutlineAccountBox className="w-5 h-5" />
                                                        <span className={cn(`block truncate`)}>{account.name}</span>
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                            <Divider className="bg-neutral-700" />
                        </div>
                        <div className="py-4">
                            {showLayoutSwitch && !location.pathname.includes('/trader') && (
                                <Fragment>
                                    <DrawerItemButton
                                        label="XplorSpot"
                                        Icon={MdCandlestickChart}
                                        onClick={() => navigate('/')}
                                        disabled={currentAccountType !== 'NOP'}
                                        disabledMessage="Margin accounts are not supported in XplorSpot"
                                    />
                                    <DrawerItemButton
                                        label="XplorSpotLite"
                                        Icon={MdOfflineBolt}
                                        onClick={() => navigate('/lite')}
                                        disabled={currentAccountType !== 'NOP'}
                                        disabledMessage="Margin accounts are not supported in XplorSpotLite"
                                    />
                                    {location.pathname.includes('/lite') && (
                                        <DrawerItemButton
                                            label="History"
                                            Icon={MdHistory}
                                            onClick={() => navigate('/lite/history')}
                                        />
                                    )}
                                </Fragment>
                            )}
                            {depositEnabled && (
                                <DrawerItemButton
                                    label="Deposits"
                                    Icon={MdOutlineAccountBalanceWallet}
                                    onClick={depositDisclosure[1].toggle}
                                />
                            )}
                            {withdrawalEnabled && (
                                <DrawerItemButton
                                    label="Withdrawals"
                                    Icon={MdOutlineAccountBalance}
                                    onClick={withdrawalDisclosure[1].toggle}
                                />
                            )}
                            <DrawerItemButton
                                label="Settings"
                                Icon={MdOutlineSettings}
                                onClick={settingsDisclosure[1].toggle}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex gap-3 p-4 text-neutral-200 font-semibold text-xs">
                            {env && env !== 'prod' && (
                                <div className="w-fit cursor-default">
                                    <div className="py-1 px-4 rounded-md uppercase bg-brand-primary">{env}</div>
                                </div>
                            )}
                            <div className="py-1 px-4 rounded-md uppercase bg-neutral-700">
                                build : {buildJson.build}
                            </div>
                        </div>

                        <Divider className="bg-neutral-700" />
                        <div className="flex flex-col gap-2 py-4 text-neutral-200">
                            <div className="text-neutral-400 text-xs px-4">{credentials?.username}</div>
                            <DrawerItemButton
                                label="Log out"
                                Icon={MdOutlineLogout}
                                onClick={() => onLogout(credentials, dispatch)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {drawerDisclosure[0] && (
                <div
                    className="block lg:hidden fixed z-20 inset-0 bg-black opacity-50"
                    onClick={drawerDisclosure[1].close}
                />
            )}
        </>
    );
};

export default MobileDrawer;

interface DrawerItemButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    Icon: IconType;
    label: string;
    disabledMessage?: string;
}

const DrawerItemButton = (props: DrawerItemButtonProps) => {
    const { Icon, label, disabledMessage, ...restProps } = props;
    const { disabled } = restProps;
    return (
        <button
            title={disabled ? disabledMessage : ''}
            className={cn('group flex w-full gap-4 items-center px-4 py-2 text-sm', {
                'text-neutral-400 cursor-not-allowed': disabled,
                'text-neutral-200 hover:bg-brand-red': !disabled
            })}
            {...restProps}>
            <span>
                <Icon className="h-5 w-5" />
            </span>
            <div className="truncate whitespace-nowrap">{label}</div>
        </button>
    );
};
