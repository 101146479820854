// generic button with loading animation

import cn from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import Loader from './Loader';

interface IButton extends ComponentProps<'button'> {
    onClick?(): void;
    isLoading?: boolean;
    children: ReactNode;
    className?: string;
    loader?: ReactNode;
}

export const Button = (props: IButton) => {
    const { isLoading, children, loader, className, disabled, ...restProps } = props;

    return (
        <button
            type="submit"
            {...restProps}
            className={cn(
                'w-full flex items-center justify-center gap-3 text-sm py-2 px-4 border border-transparent rounded-md',
                'shadow-sm text-white font-medium focus:outline-none focus:ring-2 focus:ring-brand-red-light',
                { 'bg-brand-red hover:bg-brand-red-light cursor-pointer': !disabled },
                { 'bg-neutral-700 hover:bg-neutral-600 focus:ring-neutral-600 cursor-not-allowed': disabled },
                className
            )}
            disabled={isLoading || disabled}>
            {isLoading && (
                <>
                    {loader ? loader : <Loader className="ml-0 mr-0" />}
                    Loading...
                </>
            )}
            {!isLoading && children}
        </button>
    );
};
