import ExportCSV from '@/components/common/ExportCSV';
import ClosePositionModal from '@/components/modal/ClosePositionModal';
import HistoricSearchModal from '@/components/modal/HistoricSearchModal';
import { HistoricSearch } from '@/components/modules/Blotters';
import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import { useAppDispatch } from '@/state/hooks';
import { setActiveTicker } from '@/state/reducers/tickerSlice';
import { getCurrency } from '@/utils/hooks/useCurrency';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { UseScreenSizesReturn } from '@/utils/hooks/useScreenSizes';
import { useCallback, useState } from 'react';
import Blotters from '.';
import { OpenPositionRow } from './OpenPositionTable';

interface BlottersModuleProps {
    screen: UseScreenSizesReturn;
    tradingModule: {
        title: string;
        component?: JSX.Element | undefined;
    };
}

const BlottersModule = (props: BlottersModuleProps) => {
    const { screen, tradingModule } = props;
    const dispatch = useAppDispatch();

    const [CSVData, setCSVData] = useState<any[]>([]);
    const [prefillValues, setPrefillValues] = useState<OpenPositionRow>();

    const orderDisclosure = useDisclosure(false);
    const historicSearchDisclosure = useDisclosure(false);

    const onOrderModal = useCallback(
        (row: OpenPositionRow) => {
            orderDisclosure[1].toggle();
            const ccy = getCurrency(row.instrument);
            dispatch(
                setActiveTicker({
                    show: ccy.show,
                    celer: row.instrument,
                    netdania: ccy.netdania || row.instrument
                })
            );
            setPrefillValues(row);
        },
        [orderDisclosure]
    );

    return (
        <ModuleWindow
            {...props}
            renderToolbar={() => (
                <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                    <div className="h-full flex items-center">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>

                        <div className="flex items-center h-full">
                            {(screen.laptop || screen.desktop) && (
                                <div
                                    title="Download to CSV"
                                    className="cursor-pointer hover:bg-brand-red h-full px-2 flex items-center">
                                    <ExportCSV csvData={CSVData} />
                                </div>
                            )}
                            <div
                                title="Historic Search"
                                className="cursor-pointer hover:bg-brand-red h-full px-2 flex items-center">
                                <HistoricSearch handlers={historicSearchDisclosure[1]} />
                            </div>
                        </div>
                    </div>
                    <ModuleWindowControls screen={screen} />
                </div>
            )}>
            <div id="orders" className="h-full bg-brand-background">
                <Blotters setCSVData={setCSVData} onOrderModal={onOrderModal} />
            </div>
            <ClosePositionModal
                prefillValues={prefillValues}
                opened={orderDisclosure[0]}
                handlers={orderDisclosure[1]}
            />
            <HistoricSearchModal opened={historicSearchDisclosure[0]} handlers={historicSearchDisclosure[1]} />
        </ModuleWindow>
    );
};

export default BlottersModule;
