import { useEffect } from 'react';

import { Tab } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { selectSettings, setSettings } from '../../../state/reducers/settingSlice';
import BuildVersion from '../../common/BuildVersion';
import Divider from '../../common/Divider';
import { SettingsFormInput, settingsSchema } from '../../form/schema/settingsSchema';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTab, ModalTabList, ModalTitle } from '../Modal';
import AdvancedTab from './AdvancedTab';
import GeneralTab, { timezoneOptions } from './GeneralTab';
import NotificationsTab, { notificationDurationOptions, notificationShowOptions } from './NotificationsTab';
import TradingTab, {
    defaultLimitOrderDurationOptions,
    defaultMarketOrderDurationOptions,
    shortSellPreventionOptions,
    tradingLayoutOptions,
    twoClickTradingOptions
} from './TradingTab';

const tabs = ['General', 'Trading', 'Notifications', 'User'];

const defaultTradingLayout = window.config?.layout?.default;

const defaultValues: Partial<SettingsFormInput> = {
    general: {
        timezone: timezoneOptions[0]
    },
    notifications: {
        show: notificationShowOptions[1]
        // duration: notificationDurationOptions[1]
    },
    trading: {
        tradingLayout: defaultTradingLayout === 'xplorspot' ? tradingLayoutOptions[0] : tradingLayoutOptions[1],
        twoClickTrading: twoClickTradingOptions[0],
        shortSellPrevention: shortSellPreventionOptions[0],
        defaultMarketOrderDuration: defaultMarketOrderDurationOptions[0] as any,
        defaultLimitOrderDuration: defaultLimitOrderDurationOptions[0] as any
    }
};

const SettingsModal = (props: ModalProps) => {
    const { opened, handlers } = props;

    const dispatch = useDispatch();
    const settings = useAppSelector(selectSettings);

    const form = useForm<SettingsFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(settingsSchema)
    });
    const { handleSubmit, reset } = form;

    const onSubmit = async (data: SettingsFormInput, e) => {
        dispatch(
            setSettings({
                ...data,
                notifications: {
                    ...data.notifications,
                    duration: notificationDurationOptions[1]
                }
            } as any)
        );
        handlers.close();
    };

    useEffect(() => {
        if (opened) {
            reset({
                general: {
                    ...defaultValues.general,
                    ...settings.general
                },
                notifications: {
                    ...defaultValues.notifications,
                    ...settings.notifications
                },
                trading: {
                    ...defaultValues.trading,
                    ...(settings.trading as any)
                }
            });
        }
    }, [opened]);

    return (
        <Modal {...props} size="max-w-xl">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block lg:h-auto" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        <ModalTitle>Settings</ModalTitle>
                        <ModalClose handlers={handlers} />
                    </ModalHeader>
                    <Divider />
                    <div className="h-full flex flex-col lg:h-auto lg:block w-full text-neutral-200 mt-[1px]">
                        <Tab.Group>
                            <ModalTabList>
                                {tabs.map((tab) => (
                                    <ModalTab key={tab}>{tab}</ModalTab>
                                ))}
                            </ModalTabList>
                            <Tab.Panels className="flex-1 basis-0 overflow-y-auto">
                                <GeneralTab />
                                <TradingTab />
                                <NotificationsTab />
                                <AdvancedTab />
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center p-2 sm:p-4">
                        <BuildVersion />
                        <div className="justify-end flex space-x-2">
                            <button
                                type="button"
                                className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                                onClick={handlers.close}>
                                Cancel
                            </button>
                            <button type="submit" className="rounded-md p-2 px-4 bg-brand-red hover:bg-brand-red-light">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default SettingsModal;
