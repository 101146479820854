import { XplorSpotModules } from '@/config/xplorSpotLayout';
import { useAppDispatch } from '@/state/hooks';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useLayout from '@/utils/hooks/useXplorSpotLayout';

import { setXplorSpotTabletLayoutNode } from '@/state/reducers/xplorSpotLayoutSlice';
import cn from 'classnames';
import { IconType } from 'react-icons/lib';
import { MdAccountBalanceWallet, MdCandlestickChart, MdHistory, MdPriceChange } from 'react-icons/md';
import { updateTree } from 'react-mosaic-component';

const tabs: {
    label: string;
    name: XplorSpotModules;
    title: string;
    Icon: IconType;
}[] = [
    { label: 'Prices', name: 'Order Book', title: 'Market Depth', Icon: MdPriceChange },
    { label: 'Chart', name: 'Chart', title: 'Chart', Icon: MdCandlestickChart },
    { label: 'Blotters', name: 'Blotter', title: 'Blotter', Icon: MdHistory },
    { label: 'Balances', name: 'Balances', title: 'Balances', Icon: MdAccountBalanceWallet }
];

const TabletTabNavigation = () => {
    const dispatch = useAppDispatch();

    const screen = useScreenSizes();
    const { layout } = useLayout(screen);

    return (
        <div className="flex gap-0.5">
            {tabs.map(({ name, title, Icon }) => (
                <div
                    key={name}
                    className={cn(
                        'flex uppercase cursor-pointer text-neutral-200 font-semibold px-2 gap-2 items-center',
                        {
                            ['bg-brand-red']: name === layout?.first,
                            ['bg-neutral-700 hover:bg-brand-red']: name !== layout?.first
                        }
                    )}
                    onClick={() => switchTab(layout, name, dispatch)}>
                    <Icon className="w-3.5 h-3.5" />
                    {title}
                </div>
            ))}
        </div>
    );
};

export default TabletTabNavigation;

const switchTab = (tabletLayout, moduleToDrop, dispatch) => {
    let currentNode = tabletLayout;
    if (currentNode) {
        currentNode = updateTree(currentNode, [
            {
                path: [],
                spec: {
                    $set: {
                        direction: 'row',
                        first: moduleToDrop,
                        second: 'Order',
                        splitPercentage: 60
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }
    dispatch(setXplorSpotTabletLayoutNode(currentNode));
};
